<template>
    <div class="mods-app">
      <div class="mods-grid">
        <div class="mods-card" v-for="item in list" :key="item.name">
          <!-- 图片展示 -->
          <div class="mods-card-image">
            <img :src="item.poster" alt="App Poster" />
          </div>
          <!-- 内容区 -->
          <div class="mods-card-content">
            <!-- 名称和链接 -->
            <h3 class="mods-card-title">
              <a :href="item.link" target="_blank" rel="noopener">
                {{ item.name }}
              </a>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
export default {
  data() {
    return {
      list:[
        {
          name:'GBWhatsApp',
          poster: require('@/assets/logo.webp'),
          link: 'https://www.gbwhatsapp.chat/',
        },
        {
          name:'FM WhatsApp',
          poster: require('@/assets/logo1.webp'),
          link: 'https://www.gbwhatsapp.chat/fmwhatsapp-apk-download/',
        },
        {
          name:'YOWhatsApp',
          poster: require('@/assets/logo2.webp'),
          link: 'https://www.gbwhatsapp.chat/yowhatsapp-apk-download/',
        },
        {
          name:'WhatsApp Plus',
          poster: require('@/assets/logo3.webp'),
          link: 'https://www.gbwhatsapp.chat/whatsapp-plus-apk-download/',
        },
        {
          name:'OGWhatsApp',
          poster: require('@/assets/logo4.webp'),
          link: 'https://www.gbwhatsapp.chat/ogwhatsapp-apk-download/',
        },
        {
          name:'JTWhatsApp',
          poster: require('@/assets/logo5.webp'),
          link: 'https://www.gbwhatsapp.chat/jtwhatsapp-apk-download/',
        },
      ]
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.mods-app {
  max-width: 1200px; /* 最大宽度 */
  margin: 0 auto; /* 居中 */
  padding: 20px;
}

.mods-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 30px; /* 卡片间距 */
}

.mods-card {
    display: flex;
    border-radius: 12px;
    /* background: #f3f3f3; */
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding-top: 10px;
    transition: transform 0.2s ease;
    flex-direction: column;
    align-items: center;
    color: #333; /* 文字颜色 */
  &:hover {
    transform: scale(1.02); /* 悬停缩放 */
  }
}

.mods-card-image {
  width:60px;
  height: 60px; /* 图片高度 */
  background: #f5f5f5; /* 加载前背景色 */
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%; /* 图片宽度占满容器 */
    height: 100%;
    object-fit: cover; /* 保持比例并填充 */
  }
}

.mods-card-content {
  padding: 20px 10px;
  text-align: center; /* 内容居中 */
}

.mods-card-title {
  font-size: 1.2rem;
  margin: 10px 0 0;

  a {
    color: #fff;
    text-decoration: none; /* 去除下划线 */
    font-weight: 500;

    &:hover {
      color: #2c8ef4; /* 悬停颜色 */
    }
  }
}
@media (max-width: 768px) {
    .mods-grid {
        grid-template-columns: repeat(3, 1fr);
        gap: 15px; /* 卡片间距 */
    }
}
</style>
